import { HttpErrorResponse } from '@angular/common/http'
import { ErrorHandler, Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@environments/environment'
import { NotificationsService } from '@shared/services/notifications.service'
import { ModalService } from 'ngx-modal-ease'

@Injectable({
	providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private injector: Injector) {}

	handleError(error: Error): void {
		// Verificamos si el error es una instancia de HttpErrorResponse
		if (error instanceof HttpErrorResponse) {
			const notificationService = this.injector.get(NotificationsService)
			let text = 'Error inesperado.'

			switch (error.status) {
				case 401: {
					const modalService = this.injector.get(ModalService)
					modalService.closeAll()

					// Si el estado es 401 o 403, redirigimos al usuario a la página de login
					const router = this.injector.get(Router)
					localStorage.clear()
					router.navigate(['/auth/login'])
					break
				}
				case 403:
					text = 'Tiene restringido el acceso al recurso.'
					notificationService.error(text)
					break
				case 500:
					text = 'Error de servidor'
					notificationService.error(text)
					break
				case 404:
					notificationService.error(error.error.mensaje)
					break
				case 0:
					text = 'No es posible conectarse al servidor. La conexion con el servidor ha sido interumpida.'
					notificationService.error(text)
					break
				default:
					notificationService.error(text)
					break
			}
		}

		// Colocar aquí para manejar más errores

		// El error solo se imprime en consola si no estamos en producción
		if (environment.production === false) {
			console.error('Error capturado por el GlobalErrorHandler:', error)
		}
	}
}
